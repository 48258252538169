import AEM from 'base/js/aem';
import { richTextYtLinks } from 'base/js/templates/general/youtubeModal.js';

class Teaser extends AEM.Component {
    init() {
        // Add Aria-label to YouTube Image Modal Link
        const teaserImage = this.element.querySelector('.horzimage');
        const imageLink = this.element.querySelector('.cmp-image__link');
        if (imageLink && imageLink.dataset.modalType === 'youtube') {
            const modalText = teaserImage.dataset.videoModalText ?? '';
            const sanitizedModalText = modalText.replace(/\u200B/g, '');
            const openModalText = teaserImage.dataset.openModalText;
            imageLink.ariaLabel = `${sanitizedModalText} ${openModalText}`;
        }
        this.links = this.element.querySelectorAll('.card-text a');
        richTextYtLinks(this.links);
        if (this.links.length === 0) {
            this.initializeClick();
        }
    }

    initializeClick() {
        const actions = this.element.querySelectorAll('.card-link');
        const titleLink = this.element.querySelector('.card-heading-link');

        function openAction(e) {
            let link;
            if (e.currentTarget.querySelector('.card-link')) {
                link = e.currentTarget.querySelector('.card-link');
            } else if (e.currentTarget.querySelector('.card-heading-link')) {
                link = e.currentTarget.querySelector('.card-heading-link');
            }
            if (link) {
                if (link.target === '_blank') {
                    window.open(link.href);
                    e.preventDefault();
                } else {
                    window.location.href = link.href;
                    e.preventDefault();
                }
            }
        }

        if (actions.length === 1) {
            this.element.role = 'button';
            this.element.addEventListener('click', openAction, true);
        } else if (titleLink && actions.length === 0) {
            this.element.role = 'button';
            this.element.addEventListener('click', openAction, true);
        }
    }
}

export { Teaser };
